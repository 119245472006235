import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'
import Footer from '../components/footer'
import APITermsConditions from '../components/api-terms-conditions'

const APITerm = () => {
  return (
    <Layout isWhiteLogo isPurple>
      <SEO title='API Terms and Conditions' pageType='home' flowType='none' />
      <APITermsConditions />
      <Footer />
    </Layout>
  )
}

export default APITerm

import React from 'react'
import Policy from './api-term-conditions.styles'

const APITermsConditions = () => {
  return (
    <Policy>
      <Policy.content>
        <Policy.title>CREATIV.EMAIL API TERMS AND CONDITIONS</Policy.title>
        <Policy.paragraph>
          This is a legal agreement (the “Agreement”) between you and, if
          applicable, your company, organization or other legal entity for whom
          you have authority to enter into this Agreement (“Developer”) and
          Constant Contact, Inc. (“Creativ.eMail”), for us of certain of
          Creativ.eMail’s Application Programming Interfaces (“APIs”) that
          enable Developer to provide additional functionality for users of
          Creativ.eMail products or to integrate access to certain Creativ.eMail
          products into Developer’s product offerings. By clicking “I agree to
          the terms of service,” or otherwise accessing the APIs, Developer
          indicates its acceptance of this Agreement.
        </Policy.paragraph>
        <Policy.paragraph>
          <Policy.italics>Last revised January 2021</Policy.italics>
        </Policy.paragraph>
        <Policy.list>
          <Policy.titleListChild>
            Enabling Access; Developer Obligations.
          </Policy.titleListChild>
          <Policy.paragraph>
            <Policy.bold>
              1.1 Offering Integration; API Documentation.
            </Policy.bold>
             During the term of this Agreement, Developer may use the APIs to
            provide capabilities or integrations that leverage the Creativ.eMail
            products available at www.creativemail.com (the “Creativ.eMail
            Products”) into additional functionality, products, websites and/or
            services that are offered by Developer (the “Offerings”), subject to
            the terms and conditions of this Agreement. Creativ.eMail may make
            available to Developer the Creativ.eMail API and any corresponding
            reference materials (including API documentation, wrapper libraries,
            sample code and API updates and changes) and source code, which may
            be amended or revised by Creativ.eMail at any time (the
            “Creativ.eMail API Documentation”), for use for such purposes during
            the term of this Agreement.
          </Policy.paragraph>
          <Policy.paragraph>
            <Policy.bold>1.2 User Terms and Conditions.</Policy.bold> Developer
            acknowledges and agrees that users of the Offerings (“Users”) must
            be Creativ.eMail account holders for a Creativ.eMail Product in
            order to access and use such Creativ.eMail Product through the
            Offerings. Developer further acknowledges and agrees that all Users’
            access and use of the Creativ.eMail Products is subject, in all
            respects, to the User Agreements. Developer hereby agrees to either:
            (i) require all Users to agree to the User Agreements prior to
            permitting such users to access the Creativ.eMail Products through
            the Offerings; or (ii) only provide access to Creativ.eMail Products
            in a manner that requires Users to register with Creativ.eMail
            directly and accept the User Agreements. Developer further agrees
            that, to the extent Developer uses the Creativ.eMail Products on its
            own behalf or on behalf of its customers, Developer’s use shall be
            subject to the User Agreements in all respects. Developer agrees
            that it may use the APIs to add or update only customers that have
            been obtained by the User using permission-based standards that meet
            standards described in the User Agreements. The “User Agreements”
            are defined as 
            <Policy.inlineLink
              href="https://go.constantcontact.com/legal/terms"
              target="_blank"
            >
              Creativ.eMail’s Web Site and Products Terms and Conditions of Use
            </Policy.inlineLink>
            , 
            <Policy.inlineLink
              href="https://www.endurance.com/privacy/privacy"
              target="_blank"
            >
              Privacy Policy
            </Policy.inlineLink>
            , 
            <Policy.inlineLink
              href="https://go.constantcontact.com/legal/anti-spam"
              target="_blank"
            >
              Anti-Spam Policy
            </Policy.inlineLink>
            , 
            <Policy.inlineLink
              href="https://go.constantcontact.com/legal/prohibited-content"
              target="_blank"
            >
              {' '}
              Prohibited Content and Commerce Statement
            </Policy.inlineLink>
            , and any other acceptable use policy, content restrictions, user
            agreements, and other terms and conditions governing use of the
            Creativ.eMail Products, generally available through the
            Creativ.eMail websites and/or{' '}
            <Policy.inlineLink
              target="_blank"
              href="https://www.constantcontact.com/"
            >
              www.constantcontact.com
            </Policy.inlineLink>
            , as each of the foregoing may be amended by Creativ.eMail from time
            to time in its sole discretion.
          </Policy.paragraph>
          <Policy.paragraph>
            <Policy.bold>1.3 Privacy Policy; Customer Accounts.</Policy.bold>
             Developer represents, warrants and covenants that, in its operation
            of the Offerings, it will maintain and comply with a privacy policy
            that complies with applicable law and that accurately discloses how
            Developer collects, uses, stores, and discloses data provided by
            Users and third parties. Developer will post its privacy policy
            prominently in the Offerings. Further, if Developer has any login,
            customer or other account information relating to a User’s account
            with Creativ.eMail, Developer shall only use such information for
            the purposes expressly authorized by the applicable User.
          </Policy.paragraph>
          <Policy.paragraph>
            <Policy.bold>1.4 Responsibility for Offerings.</Policy.bold>
             Developer is solely responsible for the Offerings and Creativ.eMail
            shall have no liability or obligations with respect to the same
            (including support obligations). Developer represents, warrants and
            covenants that Developer has and will at all times maintain the
            right to provide all Offerings provided by Developer hereunder and
            that the Offerings (and any other materials provided to
            Creativ.eMail or Users) do not infringe the intellectual property or
            other rights of any third parties or contain viruses, worms, malware
            or any other harmful scripts or code. Developer agrees to provide
            support for its Offerings. Developer shall ensure that all Offerings
            that access the Creativ.eMail Products comply with all applicable
            laws and regulations, including all applicable data privacy laws.
          </Policy.paragraph>
          <Policy.titleListChild>Licenses; Restrictions.</Policy.titleListChild>
          <Policy.paragraph>
            <Policy.bold>2.1 License.</Policy.bold> Subject to the terms and
            conditions herein, Creativ.eMail hereby grants to Developer a
            revocable, non-exclusive, non-transferable, non-sublicenseable,
            limited license to use and integrate the APIs into the Offering for
            the sole purpose of developing the integration to the Offering and
            allowing access to the Creativ.eMail Products via the Offering. Once
            integrated into the Offering, access to the APIs may then be
            distributed to the Users as an integrated part of the Offering.
          </Policy.paragraph>
          <Policy.paragraph>
            <Policy.bold>2.2 Responsibilities; Restrictions.</Policy.bold>
             Developer shall implement the APIs in accordance with the
            Creativ.eMail API Documentation. Developer may not access the APIs
            if Developer is a competitor of Creativ.eMail, as determined by
            Creativ.eMail in its sole discretion, or to replicate or attempt to
            replicate the essential user experience of the Creativ.eMail
            Products. Except as expressly provided herein, Developer has no
            other right to install, integrate, use, reproduce, sublicense or
            distribute APIs. Developer shall not: (i) modify, reverse engineer,
            decompile, or otherwise alter or attempt to gain access to the APIs
            or the Creativ.eMail Products in a manner not in accordance with
            this Agreement, (ii) use or enable its customers to use the APIs for
            the purposes of testing or comparison of Creativ.eMail Products or
            for any purpose competitive with Creativ.eMail Products, (iii) rent,
            lease, resell, or distribute the APIs on a stand-alone basis or for
            commercial purposes for direct commercial or monetary gain, or (iv)
            perform bulk operations with APIs that are designed for single
            contact operations or perform single contact operations with APIs
            that are designed for performing bulk operations. Developer agrees
            to protect the security and confidentiality of any credentials and
            API keys disclosed by Creativ.eMail hereunder.
          </Policy.paragraph>
          <Policy.paragraph>
            <Policy.bold>2.3 Ownership; No Other Licenses.</Policy.bold> The
            APIs contained in the Offering shall remain the sole and exclusive
            intellectual property of Creativ.eMail and Developer shall
            reasonably assist Creativ.eMail in protecting such ownership. No
            other licenses or rights in any of Creativ.eMail’s intellectual
            property rights are granted hereunder. For example and without
            limitation, no rights are granted to use Creativ.eMail’s logos or
            trademarks; provided, however that Developer may refer to the names
            of the Creativ.eMail Products solely for the purpose of describing
            the Offering.
          </Policy.paragraph>
          <Policy.paragraph>
            <Policy.bold>
              2.4 Right to Developer’s Ideas, Logo and Name.
            </Policy.bold>
             Developer hereby grants to Creativ.eMail a non-exclusive,
            royalty-free, irrevocable, perpetual, worldwide license (i) to use
            any ideas that Creativ.eMail learns from observing Developer’s
            Offerings or other use of the APIs or any feedback provided by
            Developer and (ii) to use and display Developer’s name and logo for
            the purpose of disclosing that Developer is providing Offerings
            using the APIs and for promotion of the availability of the APIs.
          </Policy.paragraph>
          <Policy.titleListChild>Non-Exclusivity</Policy.titleListChild>
          <Policy.paragraph>
            Developer acknowledges that Developer’s right to use and demonstrate
            the Creativ.eMail Products hereunder is non-exclusive, and that
            Creativ.eMail reserves the right to sell and distribute any of its
            services to any customers in the world, and to appoint any third
            party to do so, without giving Developer notice thereof and without
            incurring any liability to Developer therefore. Creativ.eMail
            reserves the right to develop and extend its products and
            capabilities without regard to whether those products compete with
            or invalidate any Developer Offering. Unless otherwise mutually
            agreed by the parties, Creativ.eMail may contact directly any User
            for the purpose of marketing and selling the Creativ.eMail Products
            and other products and services. Unless otherwise mutually agreed by
            the Parties, in the event that such User elects to purchase the
            Creativ.eMail Products, Creativ.eMail shall have no obligation to
            Developer with respect to such transaction.
          </Policy.paragraph>
          <Policy.titleListChild>
            Security and Data Privacy.
          </Policy.titleListChild>
          <Policy.paragraph>
            Developer represents and warrants that Developer’s networks,
            operating system and software (collectively, Developer’s “Systems”)
            are properly configured to securely operate the Offerings. Developer
            must promptly report any security incidents impacting Developer’s
            Systems that also impact or compromise the Offerings or
            Creativ.eMail’s APIs to Creativ.eMail in accordance with Section
            8.11 hereof. Developer will work with Creativ.eMail to correct any
            security deficiency or incident promptly, at Developer’s own
            expense. In the event Developer develop an Offering for Users to
            direct Creativ.eMail to process their data, Developer understands
            that Creativ.eMail is acting as a data processor, and Developer
            agrees to Creativ.eMail{' '}
            <Policy.inlineLink
              href="https://go.constantcontact.com/legal/api-v3/data-processing-addendum"
              target="_blank"
            >
              Data Processing Addendum.
            </Policy.inlineLink>
          </Policy.paragraph>
          <Policy.titleListChild>
            Indemnification; Limitation of Liability; Disclaimer.
          </Policy.titleListChild>
          <Policy.paragraph>
            <Policy.bold>5.1 Indemnification.</Policy.bold> Developer shall
            defend, indemnify and hold Creativ.eMail and its underlying service
            providers, business partners, third-party suppliers and providers,
            members of its network, account providers, licensors, officers,
            directors, employees, distributors and agents harmless from and
            against any lawsuit, claim, damage, liability, or expense (including
            reasonable attorneys’ fees) incurred by Creativ.eMail as a result of
            any third-party claim against Creativ.eMail resulting from or
            relating to the Offering, Developer’s use of the APIs,
            Creativ.eMail’s use of the Developer’s marks, the content on
            Developer’s website, Developer’s unauthorized marketing, promotion,
            use or distribution of the Creativ.eMail Products, Developer’s
            failure to abide by the applicable terms of any User Agreement,
            Developer’s breach of this Agreement, or the infringement or
            misappropriation of any patent, copyright, trademark, or other
            intellectual property right of any third party.
          </Policy.paragraph>
          <Policy.paragraph>
            <Policy.bold>5.2 Limitation of Liability.</Policy.bold> EXCEPT WITH
            RESPECT TO DEATH OR PERSONAL INJURY DUE TO THE NEGLIGENCE OF
            CREATIV.EMAIL, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
            UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY, TORT, CONTRACT, OR
            OTHERWISE, SHALL CREATIV.EMAIL OR ANY OF ITS UNDERLYING SERVICE
            PROVIDERS, BUSINESS PARTNERS, THIRD PARTY SUPPLIERS AND PROVIDERS
            AND MEMBERS OF ITS NETWORK, ACCOUNT PROVIDERS, LICENSORS, OFFICERS,
            DIRECTORS, EMPLOYEES, DISTRIBUTORS OR AGENTS (COLLECTIVELY REFERRED
            TO FOR PURPOSES OF THIS SECTION AS “CREATIV.EMAIL”) BE LIABLE TO
            DEVELOPER OR ANY OTHER PERSON FOR ANY MONEY DAMAGES, WHETHER DIRECT,
            INDIRECT, SPECIAL, INCIDENTAL, COVER, RELIANCE OR CONSEQUENTIAL
            DAMAGES, EVEN IF CREATIV.EMAIL SHALL HAVE BEEN INFORMED OF THE
            POSSIBILITY OF SUCH DAMAGES, OR FOR ANY CLAIM BY ANY OTHER PARTY,
            AND REGARDLESS OF THE FORM OF ACTION (WHETHER IN CONTRACT, TORT
            (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR OTHERWISE), THE MAXIMUM
            AGGREGATE LIABILITY OF CREATIV.EMAIL TO DEVELOPER ARISING IN
            CONNECTION WITH THIS AGREEMENT SHALL BE LIMITED TO $100. SOME
            JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL
            OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION AND EXCLUSION MAY NOT
            APPLY.
          </Policy.paragraph>
          <Policy.paragraph>
            Developer agrees that Creativ.eMail has made the APIs available and
            entered into this Agreement in reliance upon the disclaimers of
            warranty and the limitations of liability set forth herein, that
            they reflect an allocation of risk between the parties (including
            the risk that a contract remedy may fail of its essential purpose
            and cause consequential loss), and that they form an essential basis
            of the bargain between the parties.
          </Policy.paragraph>
          <Policy.paragraph>
            <Policy.bold>5.3 Disclaimer.</Policy.bold> DEVELOPER EXPRESSLY
            AGREES THAT THE APIS AND THE CREATIV.EMAIL PRODUCTS ARE PROVIDED ON
            AN “AS IS” AND “AS AVAILABLE” BASIS. USE OF THE APIS OR THE
            CREATIV.EMAIL PRODUCTS AND ANY RELIANCE BY DEVELOPER UPON THE APIS
            OR THE CREATIV.EMAIL PRODUCTS, INCLUDING ANY ACTION TAKEN BY
            DEVELOPER BECAUSE OF SUCH USE OR RELIANCE, IS AT DEVELOPER’S SOLE
            RISK. CREATIV.EMAIL DOES NOT WARRANT THAT THE USE OF THE APIS OR THE
            CREATIV.EMAIL PRODUCTS WILL BE UNINTERRUPTED OR ERROR FREE, NOR DOES
            CREATIV.EMAIL MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE
            OBTAINED FROM USE OF THE SAME. CREATIV.EMAIL DISCLAIMS ALL
            WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE AND NONINFRINGEMENT. NO STATEMENT OR INFORMATION, WHETHER
            ORAL OR WRITTEN, OBTAINED FROM CREATIV.EMAIL IN ANY MEANS OR FASHION
            SHALL CREATE ANY WARRANTY NOT EXPRESSLY AND EXPLICITLY SET FORTH IN
            THIS AGREEMENT. CREATIV.EMAIL MAY MODIFY OR TERMINATE OR RESTRICT
            ACCESS TO THE APIS AT ANY TIME WITHOUT NOTICE. CREATIV.EMAIL SHALL
            HAVE NO LIABILITY OF ANY NATURE WHATSOEVER FOR DEVELOPER’S
            COMPLIANCE WITH OR BREACH OF ANY LICENSE OR TERMS AND CONDITIONS OF
            ANY THIRD PARTIES OR THIRD PARTY SERVICES.
          </Policy.paragraph>
          <Policy.paragraph>
            NO CLAIM MAY BE ASSERTED BY DEVELOPER AGAINST CREATIV.EMAIL MORE
            THAN 12 MONTHS AFTER THE DATE OF THE CAUSE OF ACTION UNDERLYING SUCH
            CLAIM. DEVELOPER’S SOLE AND EXCLUSIVE REMEDY FOR ANY FAILURE OR
            NONPERFORMANCE OF THE APIS OR THE PRODUCTS SHALL BE FOR
            CREATIV.EMAIL TO USE COMMERCIALLY REASONABLE EFFORTS TO ADJUST OR
            REPAIR THE APIS OR THE PRODUCTS. DEVELOPER HEREBY WAIVES CALIFORNIA
            CIVIL CODE SECTION 1542 (IF DEVELOPER IS A CALIFORNIA RESIDENT), AND
            ANY SIMILAR PROVISION IN ANY OTHER JURISDICTION (IF DEVELOPER IS A
            RESIDENT OF SUCH JURISDICTION).
          </Policy.paragraph>
          <Policy.titleListChild>Term; Termination.</Policy.titleListChild>
          <Policy.paragraph>
            Developer may terminate this Agreement at any time by ceasing to
            provide Offerings. Creativ.eMail may terminate this Agreement and/or
            disable Developer’s ability to provide Offerings via the APIs, in
            each case at any time with or without cause, and with or without
            notice. Creativ.eMail shall have no liability to Developer or any
            third party because of such termination or action. This Agreement
            terminates automatically if Developer breaches any term of this
            Agreement. The following provisions shall survive expiration or
            termination of this Agreement: Sections 1 (Enabling Access;
            Developer Obligations), 3 (Non-Exclusivity), 4 (Security and Data
            Privacy), 5 (Indemnification; Limitation of Liability; Disclaimer),
            6 (Term; Termination) and 8 (Miscellaneous).
          </Policy.paragraph>
          <Policy.titleListChild>
            Restricted Persons; Export of Products or Technical Data.
          </Policy.titleListChild>
          <Policy.paragraph>
            Developer hereby warrants that Developer is not a Restricted Person.
            For purposes of this Agreement, Developer is a Restricted Person if
            Developer or any officer, director, or controlling shareholder of
            Developer is (i) a national of or an entity existing under the laws
            of any country with which U.S. persons are prohibited from engaging
            in transactions, as may be determined from time to time by the U.S.
            Treasury Department; (ii) designated as a Specially Designated
            National or institution of primary money laundering concern by the
            U.S. Treasury Department; (iii) listed on the Denied Persons List or
            Entity List by the U.S. Commerce Department; (iv) engaged in
            nuclear, missile, chemical or biological weapons activities to which
            U.S. persons may not contribute without a U.S. Government license;
            or (v) owned, controlled, or acting on behalf of a Restricted
            Person.
          </Policy.paragraph>
          <Policy.paragraph>
            If Developer becomes a Restricted Person during the term of this
            Agreement, Developer shall notify Creativ.eMail (
            <Policy.inlineLink href="mailto:legal@constantcontact.com">
              legal@constantcontact.com
            </Policy.inlineLink>
            ) within twenty-four (24) hours, and Creativ.eMail shall have the
            right to terminate any further obligations to Developer, effective
            immediately and with no further liability to Developer, but without
            prejudice to Developer’s outstanding obligations to Creativ.eMail.
            Developer agrees that Developer shall not utilize the Products to
            conduct or facilitate any transaction with any Restricted Person,
            except as may be expressly authorized in advance in writing by the
            U.S. Government. Developer may not remove or export from the United
            States or allow the export or re-export of the Products, or any
            direct product thereof, including technical data, in violation of
            any restrictions, laws, or regulations of the United States or any
            other applicable country.
          </Policy.paragraph>
          <Policy.titleListChild>Miscellaneous</Policy.titleListChild>
          <Policy.paragraph>
            <Policy.bold>8.1 Full Force and Effect.</Policy.bold> If any
            provision of this Agreement is found to be unenforceable or invalid,
            that provision will be limited or eliminated to the minimum extent
            necessary so that this Agreement will otherwise remain in full force
            and effect and enforceable.
          </Policy.paragraph>
          <Policy.paragraph>
            <Policy.bold>8.2 Entire Agreement.</Policy.bold> Creativ.eMail and
            Developer agree that this Agreement is the complete and exclusive
            statement of the mutual understanding of the parties and supersedes
            and cancels all previous written and oral agreements,
            communications, and other understandings relating to the subject
            matter of this Agreement, and that this Agreement may be amended
            from time to time by Creativ.eMail with or without advance notice to
            Developer. No delay or omission by either party in exercising any
            right or remedy under this Agreement or existing at law or equity
            shall be considered a waiver of such right or remedy. In the event
            of any inconsistency between this Agreement and the User Agreements,
            this Agreement controls.
          </Policy.paragraph>
          <Policy.paragraph>
            <Policy.bold>8.3 Assignment.</Policy.bold> Developer may not assign
            any of its rights hereunder. Creativ.eMail may assign all rights to
            any other individual or entity in its sole discretion.
          </Policy.paragraph>
          <Policy.paragraph>
            <Policy.bold>8.4 Further Assurances.</Policy.bold> Developer agrees
            to execute any and all documents and take any other actions
            reasonably required to effectuate the purposes of this Agreement.
          </Policy.paragraph>
          <Policy.paragraph>
            <Policy.bold>8.5 Third Party Beneficiaries.</Policy.bold>
             Creativ.eMail’s underlying service providers, business partners,
            third-party suppliers and providers, members of its network, account
            providers, licensors, officers, directors, employees, distributors
            and agents are expressly made third party beneficiaries of this
            Agreement. Except as set forth in the immediately preceding
            sentence, nothing express or implied in this Agreement is intended
            to confer, nor shall anything herein confer, upon any person other
            than the parties and the respective permitted successors or assigns
            of the parties, any rights, remedies, obligations or liabilities
            whatsoever.
          </Policy.paragraph>
          <Policy.paragraph>
            <Policy.bold>8.6 Titles.</Policy.bold> The titles of the paragraphs
            of this Agreement are for convenience only and have no legal or
            contractual effect.
          </Policy.paragraph>
          <Policy.paragraph>
            <Policy.bold>8.7 No Agency.</Policy.bold> Except as expressly set
            forth herein, no agency, partnership, joint venture, or employment
            is created as a result of this Agreement, and Developer does not
            have any authority of any kind to bind Creativ.eMail in any respect
            whatsoever.
          </Policy.paragraph>
          <Policy.paragraph>
            <Policy.bold>8.8 Attorney Fees.</Policy.bold> In any action or
            proceeding to enforce rights under this Agreement, the prevailing
            party will be entitled to recover its costs and attorneys’ fees.
          </Policy.paragraph>
          <Policy.paragraph>
            <Policy.bold>8.9 Authority.</Policy.bold> Developer represents that
            Developer has the full power, capacity and authority to accept this
            Agreement. If Developer is accepting on behalf of its employer or
            another entity, Developer represents that it has full legal
            authority to bind its employer or such entity to this Agreement.
          </Policy.paragraph>
          <Policy.paragraph>
            <Policy.bold>8.10 Governing Law and Legal Actions.</Policy.bold>
             This Agreement shall be governed by the laws of the Commonwealth of
            Massachusetts, USA and all claims relating to or arising out of this
            Agreement, or the breach thereof, whether sounding in contract, tort
            or otherwise, shall likewise be governed by the laws of the
            Commonwealth of Massachusetts, in each case, without regard to its
            choice or law or conflict of laws provisions. All legal actions in
            connection with this Agreement shall be brought in the state or
            federal courts located in Boston, Massachusetts.
          </Policy.paragraph>
          <Policy.paragraph>
            <Policy.bold>8.11 Notices.</Policy.bold> Developer agrees that
            Creativ.eMail may provide notice to Developer by emailing such
            notice to the email address listed by Developer during Developer’s
            registration. Such notice shall be considered to be received by
            Developer within 24 hours of the time it is emailed to Developer
            unless Creativ.eMail received notice that it was not delivered. Any
            notice to Creativ.eMail must be sent by postal mail to: Constant
            Contact, Inc., 1601 Trapelo Road, Waltham, Massachusetts 02451,
            Attention: Legal Team.
          </Policy.paragraph>
          <Policy.paragraph>
            <Policy.bold>8.12 Equitable Relief.</Policy.bold> Developer agrees
            that any violation or threatened violation of this Agreement may
            cause irreparable injury to Creativ.eMail, entitling Creativ.eMail
            to obtain injunctive or other equitable relief in addition to all
            legal remedies.
          </Policy.paragraph>
        </Policy.list>
      </Policy.content>
    </Policy>
  )
}

export default APITermsConditions
